<!--
 * @Description: 关于我们页面组件，未完成
 * @Author: hai-27
 * @Date: 2020-02-07 16:23:00
 * @LastEditors: hai-27
 * @LastEditTime: 2020-03-12 19:36:46
 -->
<template>
    <div class="about" id="about" name="about">
        <div class="about-header">
            <div class="about-title">
                <i class="el-icon-tickets" style="color: #ff6700;"></i>
                关于我们
            </div>
        </div>
        <div class="about-content">
            <el-row style="width: 1220px" class="p-1">
                <!--左侧商品列表-->
                <el-col :span="24" class="p-2">
                    <el-collapse v-model="activeName" >
                        <el-collapse-item  name="1" >
                            <template slot="title">
                                <span style="font-size:18px">
                                    公司简介
                                </span>
                                <!-- <i class="header-icon el-icon-info"></i> -->
                            </template>
                            <div class="instructions-main" style="font-size:16px;">
                                <p style="text-indent:2em;padding: 10px 50px 10px 50px;">
                                    中煤时代资产经营管理有限公司（以下简称“公司”）于2016年经中国中煤能源集团有限公司批准，由中国地方煤矿有限公司出资成立，是中国资产交易网平台的经营主体。公司自成立以来，一直致力于为企事业单位提供资产处置网络竞拍、国资进场交易代理、资产处置咨询和方案设计、法律咨询、清算注销等系列专业化服务，连年获得北京产权交易所、上海联合产权交易所、天津产权交易中心等大型国有产权交易平台颁发的优质服务奖、融资策划奖、交易创新奖、最佳组织奖等荣誉。公司将秉持公平、公开、公正、诚信、独立的原则，不断强化服务能力，持续提升服务效率，向社会各界提供满意的服务，构建协作共赢的生态服务圈！
                                </p>
                                <!-- <p style="margin:10px 0px 10px 0px;text-indent:2em;">
                                公司自成立以来，一直致力于为企事业单位提供资产处置网络竞拍、国资进场交易代理、资产处置咨询和方案设计、法律咨询、清算注销等系列专业化服务，连年获得北京产权交易所、上海联合产权交易所、天津产权交易中心等大型国有产权交易平台颁发的优质服务奖、融资策划奖、交易创新奖、最佳组织奖等荣誉。
                                </p>
                                <p style="margin-bottom:10px;text-indent:2em;">
                                公司将秉持公平、公开、公正、诚信、独立的原则，不断强化服务能力，持续提升服务效率，向社会各界提供满意的服务，构建协作共赢的生态服务圈！
                                </p> -->
                            </div>
                        </el-collapse-item>
                        <el-collapse-item  name="2" >
                            <template slot="title">
                                <span style="font-size:18px">
                                    平台介绍
                                </span>
                            </template>
                            <div class="instructions-main" style="font-size:16px;">
                                <p style="text-indent:2em;padding: 10px 50px 10px 50px;">
                                    中国资产交易网为各类闲废资材、闲废设备、闲废车辆、工业固废等实物资产提供专业、稳定、安全的线上实时竞价服务，致力于打造资产处置一站式服务平台，为转让方提供专业、高效、公开的处置途径，为受让方提供简洁、便捷、公平的竞买体验，通过交易规则、项目资源、竞价过程等全业务流程的公开公示，确保资产处置过程合法合规，防范国有资产流失风险，促进国有资产处置保值增值。
                                </p>
                            </div>
                        </el-collapse-item>
                        <el-collapse-item  name="3" >
                            <template slot="title">
                                <span style="font-size:18px">
                                    联系我们
                                </span>
                            </template>
                            <div class="instructions-main" style="font-size:16px;">
                                <p style="text-indent:2em;padding: 10px 50px 10px 50px;">
                                    中煤时代资产经营管理有限公司
                                </p>
                                 <p style="text-indent:2em;padding: 10px 50px 10px 50px;">
                                    热线电话：400-061-7109
                                </p>
                                 <p style="text-indent:2em;padding: 10px 50px 10px 50px;">
                                    注册地址：北京市东城区安定门外大街168号2号楼1层2号
                                </p>
                            </div>
                        </el-collapse-item>
                        <el-collapse-item  name="4" style="display:none">
                            <template slot="title">
                                <span style="font-size:18px">
                                    法律声明
                                </span>
                                <!-- <i class="header-icon el-icon-info"></i> -->
                            </template>
                            <div class="instructions-main" style="font-size:16px;">
                                <p style="margin:10px 50px 0 50px;text-indent:2em;">
                                    本网站为中国地方煤矿有限公司所属网站。本声明在本网站首页及相关页面发布。
                                </p>
                                <p style="margin:10px 50px 10px 50px;text-indent:2em;">
                                    本网站郑重提示：一旦您进入本网站，即被视为已完全知悉、理解并接受本声明的全部内容。中国地方煤矿有限公司将视情况随时更新本网站，修改本"法律声明"，您应受任何此等修改的约束。为此，请您务必定期浏览本页，以获知约束您与本网站的本文最新内容。如果您继续使用本网站，即会被视为已接受修改的内容。
                                </p>
                                <p style="margin:10px 50px 10px 50px;font-weight:bold">
                                    版权声明
                                </p>
                                <p style="margin:10px 50px 10px 50px;text-indent:2em;">
                                    1、除法律另有规定或者与中国地方煤矿有限公司另有约定外，本网站公开或显示的所有内容（包括但不限于文字、图表、照片等）及相关软件程序的知识产权均归属中国地方煤矿有限公司，受中国知识产权法及相关法律保护。
                                </p>
                                <p style="margin:10px 50px 10px 50px;text-indent:2em;">
                                    2、本网站中所标示出的"版权所有"是中国地方煤矿有限公司对于整个网站的本站设计思想、本站构架以及本站自主设计、编写、制作的内容和图像等具有自主权限的版权声明。
                                </p>
                                <p style="margin:10px 50px 10px 50px;font-weight:bold">
                                    版权声明
                                </p>
                                <p style="margin:10px 50px 10px 50px;text-indent:2em;">
                                    1、除法律另有规定或者与中国地方煤矿有限公司另有约定外，本网站公开或显示的所有内容（包括但不限于文字、图表、照片等）及相关软件程序的知识产权均归属中国地方煤矿有限公司，受中国知识产权法及相关法律保护。
                                </p>
                                <p style="margin:10px 50px 10px 50px;text-indent:2em;">
                                    2、本网站中所标示出的"版权所有"是中国地方煤矿有限公司对于整个网站的本站设计思想、本站构架以及本站自主设计、编写、制作的内容和图像等具有自主权限的版权声明。
                                </p>
                                <p style="margin:10px 50px 10px 50px;font-weight:bold">
                                    法律声明
                                </p>
                                <p style="margin:10px 50px 10px 50px;text-indent:2em;">
                                    1、您使用本网站，应遵守中国有关法律与本声明，不得破坏本网站运行安全，不得利用本网站进行任何违法活动，不得损害中国地方煤矿有限公司的合法权益。
                                </p>
                                <p style="margin:10px 50px 10px 50px;text-indent:2em;">
                                    2、在遵守中国有关法律与本声明的前提下，您可基于非商业目的或为己方从事产权转让、受让目的浏览、下载本网站的内容。如出于其他商业营利目的，以下列包括但不限于拷贝、下载、存贮、通过硬拷贝或电子抓取系统、发送、转换、出租、演示、转载、修改、传播、出版或任何其它形式传播散发本网站内容，或创造与本网站内容相关的派生产品，则必须通过北京产权交易所的书面许可，并在使用时注明来源和版权系中国地方煤矿有限公司所有的标记。有关书面许可的申请，可写信至：北京市东城区安外大街200号，邮政编码：100011，传真: 010-64202064。
                                </p>
                                <p style="margin:10px 50px 10px 50px;text-indent:2em;">
                                    3、如果您要使用本网站包含的由其它组织、团体或商业机构提供的内容。请直接与适当的版权持有人联系。与之相关的任何事务以及法律责任均与本网站无关。
                                </p>
                                <p style="margin:10px 50px 10px 50px;font-weight:bold">
                                    免责声明
                                </p>
                                <p style="margin:10px 50px 10px 50px;text-indent:2em;">
                                    中国地方煤矿有限公司在此特别声明对如下事宜不承担任何法律责任：
                                </p>
                                <p style="margin:10px 50px 10px 50px;text-indent:2em;">
                                    1、本网站对所提供的内容力求准确和完整，但并不对其准确性和完整性做出任何保证。对任何因直接或间接使用本网站内容而造成的损失，包括但不限于因有关内容不准确、不完整而导致的损失，中国地方煤矿有限公司不承担任何法律责任。
                                </p>
                                <p style="margin:10px 50px 10px 50px;text-indent:2em;">
                                    2、使用本网站并发布信息者，须保证所发布的信息真实、准确、完整。中国地方煤矿有限公司对通过本网站披露的上述信息的真实性、准确性、完整性不承担任何责任。
                                </p>
                                <p style="margin:10px 50px 10px 50px;text-indent:2em;">
                                    3、本网站不保证所设置外部链接的准确性和完整性。本网站到第三方网站的链接仅作为一种方便服务提供给您，中国地方煤矿有限公司没有审查过任何第三方网站，对这些网站及其内容不进行控制，也不负任何责任。如果您决定访问任何与本站链接的第三方网站，其可能带来的结果和风险全部由您自己承担。
                                </p>
                                <p style="margin:10px 50px 10px 50px;text-indent:2em;">
                                    4、本网站亦不保证您能随时、随地都可以使用本网站；或任何缺陷或错误都将得到更正；以及内容中没有病毒或其它有害成分，使用本网站之风险责任由您自己承担。
                                </p>
                                <p style="margin:10px 50px 10px 50px;text-indent:2em;">
                                    5、2021年11月30日至2021年12月31日为本网站测试期，在测试期内，本网站信息、数据可能存在遗漏等情况，中国地方煤矿有限公司对此不承担任何法律责任。
                                </p>
                                <p style="margin:10px 50px 10px 50px;font-weight:bold">
                                    责任限制
                                </p>
                                <p style="margin:10px 50px 10px 50px;text-indent:2em;">
                                    在任何情况下，本网站不承担任何由于使用或无法使用本网站所造成的赔偿责任（包括但不限于任何直接、间接、惩罚性或附带等赔偿责任），即使本网站事先已被告知发生此种赔偿的可能性。这些限制适用于所有基于合同、民事侵权、严格赔偿责任或其它因素的责任纠纷。
                                </p>
                                <p style="margin:10px 50px 10px 50px;font-weight:bold">
                                    更新与解释
                                </p>
                                <p style="margin:10px 50px 10px 50px;text-indent:2em;">
                                    1、中国地方煤矿有限公司保留不需发布任何通知而修改、暂停、终止本网站运行的权利，以及不需发布任何通知而随时增加、修改或删除这些条款的权利。
                                </p>
                                <p style="margin-bottom:10px;text-indent:2em;">
                                    2、中国地方煤矿有限公司保留对本声明以及本网站使用的解释权。
                                </p>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                </el-col>
                <!--右侧商城说明-->
            </el-row>
        </div>
    </div>
</template>
<script>
export default {
    data() {
      return {
        activeName: ['1','2','3','4']
      };
    }
  }
</script>
<style scoped lang="scss">
    @import "../assets/css/index.css";
    @import "../assets/css/page/about.scss";
</style>
